<template>
	<!-- 我的订单 -->
	<div style="background: #eee">
		<van-sticky>
			<van-nav-bar title="我的订单" left-text="返回" left-arrow @click-left="goback" />
			<!-- 标签页 -->
			<van-tabs v-model="activeName" swipeable @click="tabChange">
				<van-tab
					v-for="(item, index) in orderTabs"
					:key="index"
					:title="item.title"
					:name="item.type"
				/>
				<van-search
					v-model="params.content"
					show-action
					placeholder="充电桩类型/单号/客户名/客户电话/品牌/预约单号/ID"
					@search="onSearch"
				>
					<template #action>
						<div @click="onSearch()" style="color: red">查询</div>
					</template>
				</van-search>
			</van-tabs>
		</van-sticky>
		<!-- 列表 -->
		<van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
			<van-cell-group
				v-for="(item, index) in orderList"
				:key="index"
				style="margin: 1.2rem; text-align: left"
				inset
			>
				<div style="font-size: 1.6rem; padding-top: 2rem; text-align: center">
					{{ item.storeName }}
				</div>
				<div
					v-show="item.status == 8"
					style="
						width: 0;
						height: 0;
						border: 4rem solid transparent;
						border-top: none;
						border-right-color: #ffd01e;
						float: right;
						margin-top: -3rem;
						margin-right: 0.8rem;
						position: relative;
						margin-bottom: 0.4rem;
					"
				>
					<span
						style="
							position: absolute;
							width: 3rem;
							height: 4rem;
							line-height: 2rem;
							margin-left: 1.4rem;
							color: #fff;
							font-size: 0.8rem;
						"
						>待派单</span
					>
				</div>
				<div
					v-show="item.status == 1"
					style="
						width: 0;
						height: 0;
						border: 4rem solid transparent;
						border-top: none;
						border-right-color: #1989fa;
						float: right;
						margin-top: -3rem;
						margin-right: 0.8rem;
						position: relative;
						margin-bottom: 0.4rem;
					"
				>
					<span
						style="
							position: absolute;
							width: 3rem;
							height: 4rem;
							line-height: 2rem;
							margin-left: 1.4rem;
							color: #fff;
							font-size: 0.8rem;
						"
						>待首联</span
					>
				</div>
				<div
					v-show="item.status == 9"
					style="
						width: 0;
						height: 0;
						border: 4rem solid transparent;
						border-top: none;
						border-right-color: #1989fa;
						float: right;
						margin-top: -3rem;
						margin-right: 0.8rem;
						position: relative;
						margin-bottom: 0.4rem;
					"
				>
					<span
						style="
							position: absolute;
							width: 3rem;
							height: 4rem;
							line-height: 2rem;
							margin-left: 1.4rem;
							color: #fff;
							font-size: 0.5rem;
						"
						>&nbsp;电表申请</span
					>
				</div>
				<div
					v-show="item.status == 10"
					style="
						width: 0;
						height: 0;
						border: 4rem solid transparent;
						border-top: none;
						border-right-color: #1989fa;
						float: right;
						margin-top: -3rem;
						margin-right: 0.8rem;
						position: relative;
						margin-bottom: 0.4rem;
					"
				>
					<span
						style="
							position: absolute;
							width: 3rem;
							height: 4rem;
							line-height: 2rem;
							margin-left: 1.4rem;
							color: #fff;
							font-size: 0.5rem;
						"
						>&nbsp;等通知</span
					>
				</div>
				<!-- <div v-show="item.status == 13"
          style="width:0;height:0;border:4rem solid transparent;border-top:none;border-right-color:red;float:right;margin-top:-3rem;margin-right:0.8rem;position: relative;margin-bottom:0.4rem">
          <span
            style="position:absolute;width:3rem;height:4rem;line-height:2rem;margin-left:1.4rem;color:#fff;font-size:0.8rem;">待勘测</span>
        </div> -->
				<div
					v-show="item.status == 2"
					style="
						width: 0;
						height: 0;
						border: 4rem solid transparent;
						border-top: none;
						border-right-color: red;
						float: right;
						margin-top: -3rem;
						margin-right: 0.8rem;
						position: relative;
						margin-bottom: 0.4rem;
					"
				>
					<span
						style="
							position: absolute;
							width: 3rem;
							height: 4rem;
							line-height: 2rem;
							margin-left: 1.4rem;
							color: #fff;
							font-size: 0.8rem;
						"
						>待安装</span
					>
				</div>
				<div
					v-show="item.status == 3"
					style="
						width: 0;
						height: 0;
						border: 4rem solid transparent;
						border-top: none;
						border-right-color: #c1c2dc;
						float: right;
						margin-top: -3rem;
						margin-right: 0.8rem;
						position: relative;
						margin-bottom: 0.4rem;
					"
				>
					<span
						style="
							position: absolute;
							width: 3rem;
							height: 4rem;
							line-height: 2rem;
							margin-left: 1.4rem;
							color: #fff;
							font-size: 0.8rem;
						"
						>待审核</span
					>
				</div>
				<div
					v-show="item.status == 4"
					style="
						width: 0;
						height: 0;
						border: 4rem solid transparent;
						border-top: none;
						border-right-color: #ee0a24;
						float: right;
						margin-top: -3rem;
						margin-right: 0.8rem;
						position: relative;
						margin-bottom: 0.4rem;
					"
				>
					<span
						style="
							position: absolute;
							width: 3rem;
							height: 4rem;
							line-height: 2rem;
							margin-left: 1.4rem;
							color: #fff;
							font-size: 0.8rem;
						"
						>被驳回</span
					>
				</div>
				<div
					v-show="item.status == 5"
					style="
						width: 0;
						height: 0;
						border: 4rem solid transparent;
						border-top: none;
						border-right-color: #646566;
						float: right;
						margin-top: -3rem;
						margin-right: 0.8rem;
						position: relative;
						margin-bottom: 0.4rem;
					"
				>
					<span
						style="
							position: absolute;
							width: 3rem;
							height: 4rem;
							line-height: 2rem;
							margin-left: 1.4rem;
							color: #fff;
							font-size: 0.8rem;
						"
						>不安装</span
					>
				</div>
				<div
					v-show="item.status == 11"
					style="
						padding: 5px;
						text-align: center;
						width: 4rem;
						height: 2rem;
						background-color: #c1c2dc;
						float: right;
						margin-top: -3rem;
						margin-right: 0.8rem;
						position: relative;
						margin-bottom: 0.4rem;
					"
				>
					<span style="line-height: 2rem; color: #ffffff; font-size: 0.8rem">取消待审核</span>
				</div>
				<div
					v-show="item.status == 12"
					style="
						width: 0;
						height: 0;
						border: 4rem solid transparent;
						border-top: none;
						border-right-color: #646566;
						float: right;
						margin-top: -3rem;
						margin-right: 0.8rem;
						position: relative;
						margin-bottom: 0.4rem;
					"
				>
					<span
						style="
							position: absolute;
							width: 3rem;
							height: 4rem;
							line-height: 2rem;
							margin-left: 1.4rem;
							color: #fff;
							font-size: 0.8rem;
						"
						>已取消</span
					>
				</div>
				<div
					v-show="item.status == 6"
					style="
						width: 0;
						height: 0;
						border: 4rem solid transparent;
						border-top: none;
						border-right-color: #ee0a24;
						float: right;
						margin-top: -3rem;
						margin-right: 0.8rem;
						position: relative;
						margin-bottom: 0.4rem;
					"
				>
					<span
						style="
							position: absolute;
							width: 3rem;
							height: 4rem;
							line-height: 2rem;
							margin-left: 1.4rem;
							color: #fff;
							font-size: 0.8rem;
						"
						>待结算</span
					>
				</div>
				<div
					v-show="item.status == 7"
					style="
						width: 0;
						height: 0;
						border: 4rem solid transparent;
						border-top: none;
						border-right-color: green;
						float: right;
						margin-top: -3rem;
						margin-right: 0.8rem;
						position: relative;
						margin-bottom: 0.4rem;
					"
				>
					<span
						style="
							position: absolute;
							width: 3rem;
							height: 4rem;
							line-height: 2rem;
							margin-left: 1.4rem;
							color: #fff;
							font-size: 0.8rem;
						"
						>已结算</span
					>
				</div>
				<van-field
					label="单号"
					:value="item.orderNum"
					input-align="right"
					readonly
					label-width="5rem"
				/>
				<van-field
					label="订单类型"
					:value="orderTypeFormat(item)"
					input-align="right"
					readonly
					label-width="8rem"
				/>
				<van-field
					label="维修类型"
					:value="repairTypeFormat(item)"
					v-show="item.orderType === 2"
					input-align="right"
					readonly
					label-width="8rem"
				/>
				<van-field
					label="充电桩类型"
					:value="item.storeName"
					input-align="right"
					readonly
					label-width="8rem"
				/>
				<van-field
					label="品牌"
					:value="item.deviceBrandName"
					input-align="right"
					readonly
					label-width="8rem"
				/>
				<van-field
					label="车型"
					:value="item.modelName"
					input-align="right"
					readonly
					label-width="8rem"
				/>
				<van-field
					label="客户名"
					:value="item.customerName"
					input-align="right"
					readonly
					label-width="8rem"
				/>
				<van-field label="联系电话" input-align="right" readonly label-width="8rem">
					<template #button>
						<a :href="'tel:' + item.phone">{{ item.phone }}</a>
					</template>
				</van-field>
				<van-field
					label="所属区域"
					:value="item.regionName"
					input-align="right"
					readonly
					label-width="8rem"
				/>
				<van-field
					label="所属公司"
					:value="item.companyName"
					input-align="right"
					readonly
					label-width="8rem"
				/>
				<van-field
					label="详细地址"
					:value="item.detailAddress"
					input-align="right"
					type="textarea"
					rows="1"
					autosizeinput-align="right"
					label-width="8rem"
					readonly
				/>
				<!-- <van-field label="订单金额" :value="item.price" input-align="right" readonly label-width="8rem" /> -->
				<van-field
					label="预约单号"
					:value="item.pileModel"
					input-align="right"
					readonly
					label-width="8rem"
				/>
				<van-field
					label="ID"
					:value="item.reserve2"
					input-align="right"
					readonly
					label-width="8rem"
				/>
				<van-field
					label="车架号"
					:value="item.frameNo"
					input-align="right"
					readonly
					label-width="8rem"
				/>
        <van-field
            label="是否带桩"
            :value="item.isDockingRequired?'是':'否'"
            input-align="right"
            readonly
            label-width="8rem"
        />
				<van-field
					label="录单人"
					:value="item.recordUserName"
					input-align="right"
					readonly
					label-width="8rem"
				/>
				<van-field
					label="派单人"
					:value="item.dispatchUserName"
					input-align="right"
					readonly
					label-width="8rem"
				/>
				<van-field
					label="负责师傅"
					:value="item.masterWorkerName"
					input-align="right"
					readonly
					label-width="8rem"
				/>
				<van-field
					label="预约时间"
					:value="dateFormat(item.subscribeTime)"
					input-align="right"
					readonly
					label-width="8rem"
				/>
				<van-field
					label="订单备注"
					:value="item.orderRemark"
					type="textarea"
					input-align="left"
					readonly
					label-width="6rem"
				/>
				<van-field label="跟进备注" label-width="5rem" input-align="left" readonly v-if="false">
					<template #button>
						<div style="margin-right: 2rem; height: 5rem; overflow: auto">
							<div
								v-for="(item, index) in getFollowRemarkList(item.followRemark)"
								style="width: 200px; text-align: left; margin: 0"
								:key="index"
							>
								{{ item }}
							</div>
						</div>
					</template>
				</van-field>
				<van-row style="padding: 1rem">
					<van-col
						span="5.5"
						v-show="
							(item.status === 1 || item.status === 2 || item.status === 9) &&
							authorization('/home-admin/order/order-manage/h5/booking')
						"
					>
						<van-button type="default" size="small" @click="subscribeTimeClick(index, item)"
							>预约时间</van-button
						>
					</van-col>
					<van-col
						span="5.5"
						v-show="
							item.status === 1 && authorization('/home-admin/order/order-manage/h5/meterApply')
						"
					>
						<van-button type="default" size="small" @click="setStatus(9, item)"
							>电表申请中</van-button
						>
					</van-col>
					<van-col
						span="5.5"
						v-show="item.status === 1 && authorization('/home-admin/order/order-manage/h5/cancel')"
					>
						<van-button type="default" size="small" @click="showCancelRemarksFun(item)"
							>取消订单</van-button
						>
					</van-col>
					<van-col
						span="5.5"
						v-show="
							item.status === 1 && authorization('/home-admin/order/order-manage/h5/waitNotice')
						"
					>
						<van-button type="default" size="small" @click="setStatus(10, item)"
							>等待客户通知</van-button
						>
					</van-col>
					<van-col
						span="5.5"
						v-show="
							item.status === 9 && authorization('/home-admin/order/order-manage/h5/meterInstall')
						"
					>
						<van-button type="default" size="small" @click="setStatus(2, item)"
							>电表已装</van-button
						>
					</van-col>
					<!-- <van-col span="5.5"
            v-show="item.status === 13 && authorization('/home-admin/order/order-manage/h5/survey')">
            <van-button type="default" size="small" @click="onSurvey(item)">勘测完成</van-button>
          </van-col> -->
					<van-col
						span="5.5"
						v-show="
							item.status === 2 && authorization('/home-admin/order/order-manage/h5/installFinish')
						"
					>
						<van-button type="default" size="small" @click="goComplete(item)">安装完成</van-button>
					</van-col>
					<van-col
						span="5.5"
						v-show="
							(item.status === 1 || item.status === 2) &&
							authorization('/home-admin/order/order-manage/h5/uninstall')
						"
					>
						<van-button type="default" size="small" @click="notInstall(index, item)"
							>暂不安装</van-button
						>
					</van-col>
					<van-col span="5.5">
						<van-button type="default" size="small" @click="addRemarkClick(index, item)"
							>添加跟进</van-button
						>
					</van-col>
					<van-col
						span="5.5"
						v-show="
							(item.status === 3 || item.status === 6 || item.status === 7) &&
							authorization('/home-admin/order/order-manage/h5/detail')
						"
					>
						<van-button type="default" size="small" @click="goComplete(item)">详情</van-button>
					</van-col>

					<van-col
						span="5.5"
						v-show="item.status === 4 && authorization('/home-admin/order/order-manage/h5/edit')"
					>
						<van-button type="default" size="small" @click="goComplete(item)">修改订单</van-button>
					</van-col>
					<van-col
						span="5.5"
						v-show="
							(item.status === 5 || item.status === 10) &&
							authorization('/home-admin/order/order-manage/h5/arouse')
						"
					>
						<van-button type="default" size="small" @click="arouseClick(index, item)"
							>唤起安装</van-button
						>
					</van-col>
				</van-row>
			</van-cell-group>
		</van-list>
		<!-- 弹出层 -->
		<van-popup v-model="showTime" position="bottom">
			<van-datetime-picker
				v-model="currentDate"
				type="datetime"
				title="请选择时间"
				@confirm="confirmDateTime"
				@cancel="showTime=false"
				:min-date="minDate"
				:max-date="maxDate"
			/>
		</van-popup>
		<van-popup v-model="showRemarks" position="bottom">
			<van-field
				v-model="followRemarks"
				rows="6"
				autosize
				label="备注"
				type="textarea"
				maxlength="80"
				placeholder="请填写备注"
				show-word-limit
			/>
			<van-button type="info" style="margin: 1rem; float: right" @click="confirmRemark"
				>确定</van-button
			>
			<!-- <van-button type="primary" style="margin:1rem;float:right" @click="showShortcut=true">快捷语</van-button> -->
		</van-popup>
		<van-popup v-model="showCancelRemarks" position="bottom">
			<van-field
				v-model="cancelParams.cancelRemarks"
				rows="6"
				autosize
				label="取消原因"
				type="textarea"
				maxlength="80"
				placeholder="请填写取消原因"
				show-word-limit
			/>
			<van-button type="info" style="margin: 1rem; float: right" @click="subMitCancel"
				>确定</van-button
			>
		</van-popup>
		<!-- <van-popup v-model="showShortcut" style="width:20rem">
      <van-picker show-toolbar :columns="shortcutCol" @confirm="onShortcut" @cancel="showShortcut=false" style="font-size:10px"/>
    </van-popup> -->
		<van-popup v-model="showSurvey" position="bottom">
			<van-overlay :show="showLoading">
				<div class="wrapper" @click.stop>
					<van-loading size="24px" vertical>上传中...</van-loading>
				</div>
			</van-overlay>
			<van-form @submit="onSurveySubmit">
				<van-field
					label="下单充电桩类型"
					:value="surveyParams.storeName"
					input-align="right"
					readonly
					label-width="8rem"
				/>
				<van-field
					label="客户姓名"
					:value="surveyParams.customerName"
					input-align="right"
					readonly
					label-width="8rem"
				/>
				<van-field
					label="手机号码"
					:value="surveyParams.phone"
					input-align="right"
					readonly
					label-width="8rem"
				/>
				<van-field
					input-align="right"
					required
					name="uploader"
					label="勘测确认单"
					:rules="[{ required: true, message: '请上传勘测确认单' }]"
				>
					<template #input>
						<van-uploader
							:multiple="true"
							:max-count="40"
							v-model="surveyImageUploader"
							:before-read="imageUploadOuther"
							:name="-3"
							:before-delete="deleteImage"
						/>
					</template>
				</van-field>
				<div style="margin: 1rem">
					<van-button round block type="info" native-type="submit">提交</van-button>
				</div>
			</van-form>
		</van-popup>
	</div>
</template>
<script>
	import moment from 'moment';
	import { Dialog, Toast } from 'vant';
	import { authorization } from '../authorization/authorization';
	import { orderTypeFormat, repairTypeFormat } from '../components/common/format';
	import ImageCompressor from 'compressorjs';
	export default {
		data() {
			return {
				showCancelRemarks: false,
				activeName: this.$route.query.type === undefined ? -1 : Number(this.$route.query.type),
				showTime: false,
				showRemarks: false,
				showSurvey: false,
				flagUpload: true,
				showLoading: false,
				// showShortcut:false,
				orderTabs: [
					{ type: -1, title: '全部订单', color: '#7af' },
					{ type: -2, title: '维修单', color: '#7af' },
					{ type: -3, title: '自提单', color: '#7af' },
					{ type: 6, title: '待结算订单', color: '#fa0' },
					{ type: 7, title: '已结算订单', color: '#f00' },
					// { type: 1, title: '待首联订单', color: '#7af' },
					// { type: 9, title: '电表申请中', color: '#fa0' },
					//{ type: 13, title: "待勘测订单", color: "#f00" },
					{ type: 2, title: '待安装订单', color: '#f00' },
          { type: 16, title: '今日待安装', color: '#f02' },
					{ type: 3, title: '待审核订单', color: '#7af' },
					{ type: 4, title: '被驳回订单', color: '#fa0' },
					// { type: 5, title: '暂不安装订单', color: '#f00' },

					// { type: 10, title: '等客户通知', color: '#7af' },
					{ type: 11, title: '取消待审核', color: '#fa0' },
					{ type: 12, title: '已取消', color: '#f00' },
				],
				searchVal: '',
				orderList: [],
				//当前选中的index
				orderListIndex: 0,
				loading: false,
				finished: false,
				minDate: new Date(2020, 0, 1),
				maxDate: new Date(2025, 10, 1),
				currentDate: new Date(),
				params: {
					size: 5,
					page: 1,
					status: '',
					content: '',
				},
				cancelParams: {
					cancelRemarks: '',
					orderIds: [],
					status: '',
				},
				subscribeTimeParams: {
					subscribeTime: '',
					id: '',
				},
				addRemarkParams: {
					followRemark: '',
					orderId: '',
          customerName: '',
          phone: ""
				},
				arouseParams: {
					id: '',
					status: '',
				},
				surveyParams: {},
				surveyImageUploader: [],
				followRemarks: '',
				shortcutCol: [
					'长安7KW充电桩，无需带桩上门，充电桩在客户手里，白色的是我们安装，11张图',
					'7kw-充电桩安装施工不含电线，核销码，好评，11张图',
					'7kw-充电桩30米套包，核销码，好评，11张图',
					'比亚迪7kw充电桩，需要带桩上门，11张图',
					'比亚迪3.3kw充电桩，需要带桩上门，11张图',
				],
			};
		},
		created() {
			if (this.activeName === -1) {
				this.params.status = 0;
			} else if (this.activeName === -2) {
				this.params.status = 0;
				this.params.orderType = 2;
			} else if (this.activeName === -3) {
				this.params.status = 0;
				this.params.orderType = 3;
			} else {
				this.params.status = this.activeName;
			}
			this.queryOrderList();
		},
		methods: {
			authorization,
			orderTypeFormat,
			repairTypeFormat,
			//展示取消原因文本框
			showCancelRemarksFun(row) {
				this.cancelParams.orderIds = [];
				this.cancelParams.cancelRemarks = '';
				this.cancelParams.orderIds.push(row.id);
				this.cancelParams.status = 11;
				this.showCancelRemarks = true;
			},
			onSurvey(row) {
				this.surveyParams = JSON.parse(JSON.stringify(row));
				this.surveyImageUploader = [];
				this.handlePreviewImage(this.surveyParams.surveyImageUrl, -3);
				this.showSurvey = true;
			},
			onSurveySubmit() {
				let data = {
					updateType: 2,
					surveyImageUrl: this.surveyParams.surveyImageUrl,
					status: 2, //待安装
				};
				Dialog.confirm({
					title: '订单操作',
					message: '请确认是否进行该操作',
				}).then(() => {
					this.axios({
						method: 'post',
						url: '/v1/web/order-info/survey-order/' + this.surveyParams.id,
						data: data,
					}).then((res) => {
						if (res.data.code === 200) {
							Toast.success('勘测成功');
							this.params.page = 1;
							this.queryOrderList();
							this.showSurvey = false;
						} else {
							this.$message.error(res.data.message);
						}
					});
				});
			},
			imageUpload(file, detail) {
				if (detail.name === -1) {
					if (this.surveyParams.reserve1Uploader.length >= 40) {
						Toast.fail('最多只能上传40张照片');
						return;
					}
				}

				this.clickUpload(detail.name, file);
			},
			/**
			 * 压缩图片
			 * @param file
			 * @returns {Promise<unknown>}
			 */
			compressFile(file, quality) {
				return new Promise((resolve, reject) => {
					new ImageCompressor(file, {
						quality: quality, //压缩质量
						checkOrientation: false, //图片翻转，默认为false
						success(result) {
							resolve(result);
						},
						error(e) {
							reject(e);
						},
					});
				});
			},
			imageUploadOuther(file, detail) {
				if (file.length === undefined) {
					this.imageUpload(file, detail);
				} else {
					if (this.surveyImageUploader.length + file.length >= 40) {
						Toast.fail('最多只能上传40张照片');
						return;
					}
					for (let i = 0; i < file.length; i++) {
						this.imageUpload(file[i], detail);
					}
				}
			},
			deleteImage(file, detail) {
				if (detail.name === -3) {
					//勘测确认图
					this.surveyImageUploader.splice(detail.index, 1);
					this.surveyParams.surveyImageUrl = this.getImageUrlArray(this.surveyImageUploader);
				}
			},
			async clickUpload(type, file) {
				this.showLoading = true;
				let maxSize = 200 * 1024; //自己定义的文件大小，超过多少M就开始压缩(现在是200k)
				if (file.size > maxSize) {
					file = await this.compressFile(file, 0.2);
				}

				this.uploadImage(file, type);
			},
			uploadImage(file, type) {
				let param = new FormData(); //创建form对象
				param.append('files', file);
				param.append('orderNum', this.surveyParams.orderNum);
				this.axios({
					method: 'post',
					url: '/v1/h5/order-info/image-upload',
					data: param,
				})
					.then((res) => {
						let imgUrl = res.data.data.imageUrl;
						this.handlePreviewImage(imgUrl, type);
						this.flagUpload = true;
						this.showLoading = false;
					})
					.catch(() => {
						Toast.fail('上传失败');
						this.showLoading = false;
					});
			},
			handlePreviewImage(imgUrl, type) {
				if (imgUrl === undefined || imgUrl === null || imgUrl == '') {
					return;
				}
				//默认图片
				let imageObj = {};
				imageObj.url = '/api/v1/web/order-info/preview-image?path=' + imgUrl;
				if (type === -3) {
					//勘测确认图
					let imageArray = imgUrl.split(',');
					if (imageArray.length > 1) {
						for (let i = 0; i < imageArray.length; i++) {
							let image = {};
							image.url = '/api/v1/web/order-info/preview-image?path=' + imageArray[i];
							this.surveyImageUploader.push(image);
						}
					} else {
						this.surveyImageUploader.push(imageObj);
					}
					this.surveyParams.surveyImageUrl = this.getImageUrlArray(this.surveyImageUploader);
				}
			},
			getImageUrlArray(imageObjs) {
				let resultStr = '';
				for (let i = 0; i < imageObjs.length; i++) {
					if (resultStr !== '') {
						resultStr = resultStr + ',';
					}
					resultStr = resultStr + imageObjs[i].url.substring(imageObjs[i].url.indexOf('=') + 1);
				}
				return resultStr;
			},
			subMitCancel() {
				this.axios({
					method: 'post',
					url: '/v1/h5/order-info/cancel-order',
					data: this.cancelParams,
				}).then((res) => {
					if (res.data.code === 200) {
						Toast.success('取消成功');
						this.showCancelRemarks = false;
						this.params.page = 1;
						this.queryOrderList();
					} else {
						Toast.fail(res.data.message);
					}
				});
			},
			getFollowRemarkList(followRemark) {
				if (followRemark === undefined || followRemark === null) {
					return [];
				}
				return followRemark.split(';');
			},
			dateFormat(time) {
				if (time !== null && time !== undefined) {
					return moment(time).format('YYYY-MM-DD HH:mm:ss');
				}
			},
			goback() {
				//返回上一页
				this.$router.push('/');
			},
			onSearch() {
				//查询
				this.params.page = 1;
				this.queryOrderList();
			},
			goComplete(item) {
				//跳转到完成订单
				sessionStorage.setItem('orderId', item.id);
				this.$router.push({
					path: '/CompleteOrder',
					query: { orderId: item.id, goBackUrl: '/OrderList', type: this.activeName },
				});
			},
			arouseClick(index, item) {
				this.arouseParams.status = 2;
				this.arouseParams.id = item.id;
				this.axios({
					method: 'post',
					url: '/v1/h5/order-info/set-order-status/' + this.arouseParams.id,
					params: this.arouseParams,
				}).then((res) => {
					if (res.data.code === 200) {
						Toast.success('唤起成功');
						if (this.params.status === 0) {
							this.orderList[index].status = 5;
						} else {
							this.params.page = 1;
							this.queryOrderList();
						}
					} else {
						Toast.fail(res.data.message);
					}
				});
			},
			onLoad() {
				// 异步更新数据
				// setTimeout 仅做示例，真实场景中一般为 ajax 请求
				setTimeout(() => {
					this.params.page = this.params.page + 1;
					this.queryOrderList();
				}, 1000);
			},
			queryOrderList() {
				this.axios({
					method: 'get',
					url: '/v1/h5/order-info/select-order',
					params: this.params,
				}).then((res) => {
					if (this.params.page === 1) {
						this.orderList = [];
					}
					for (let i = 0; i < res.data.data.list.length; i++) {
						this.orderList.push(res.data.data.list[i]);
					}
					if (res.data.data.list.length <= 0) {
						this.finished = true;
					}
					// 加载状态结束
					this.loading = false;
				});
			},
			tabChange(name) {
				if (name === -1) {
					this.params.status = 0;
				} else if (name === -2) {
					this.params.orderType = 2;
				} else if (name === -3) {
					this.params.orderType = 3;
				} else {
					this.params.status = name;
				}
				this.orderList = [];
				this.params.page = 1;
				this.finished = false;
				this.queryOrderList();
			},
			confirmDateTime(value) {
				this.subscribeTimeParams.subscribeTime = value.getTime();
				this.axios({
					method: 'put',
					url: '/v1/h5/order-info/subscribe-time/' + this.subscribeTimeParams.id,
					params: this.subscribeTimeParams,
				}).then((res) => {
					if (res.data.code === 200) {
						Toast.success('预约成功');
						if (this.params.status === 0) {
							this.orderList[this.orderListIndex].subscribeTime = value;
							this.orderList[this.orderListIndex].status = 2;
						} else {
							this.params.page = 1;
							this.queryOrderList();
						}
						this.showTime = false;
					} else {
						Toast.fail(res.data.message);
					}
				});
			},
			subscribeTimeClick(index, row) {
				this.subscribeTimeParams.id = row.id;
				this.orderListIndex = index;
				this.showTime = true;
			},
			addRemarkClick(index, row) {
				this.addRemarkParams.orderId = row.id;
        // this.addRemarkParams.customerName=row.customerName;
        // this.addRemarkParams.phone=row.phone;
				this.followRemarks = '';
				this.addRemarkParams.followRemark = row.followRemark;
				this.orderListIndex = index;
				this.showRemarks = true;
			},
			confirmRemark() {
				if (
					this.addRemarkParams.followRemark === null ||
					this.addRemarkParams.followRemark === undefined
				) {
					this.addRemarkParams.followRemark = '';
				}
				this.addRemarkParams.followRemark = this.followRemarks;
					// this.addRemarkParams.followRemark +
					// moment(new Date()).format('YYYY-MM-DD HH:mm') +
					// ':;' +
					// this.followRemarks +
					// ';';
        console.log(this.addRemarkParams);
				this.axios({
					method: 'post',
					url: '/v1/web/order-info/add-follow',
					data: this.addRemarkParams,
				}).then((res) => {
					if (res.data.code === 200) {
						this.showRemarks = false;
						Toast.success('备注添加成功');
						this.orderList[this.orderListIndex].followRemark = this.addRemarkParams.followRemark;
					} else {
						Toast.fail(res.data.message);
					}
				});
			},
			// onShortcut(val){//快捷语
			//   this.addRemarkParams.followRemark=this.addRemarkParams.followRemark+"  "+val
			//   this.showShortcut=false;
			// },
			setStatus(status, item) {
				Dialog.confirm({
					title: '订单操作',
					message: '请确认是否进行该操作',
				}).then(() => {
					this.axios({
						method: 'post',
						url: '/v1/h5/order-info/set-order-status/' + item.id,
						params: {
							status: status,
						},
					}).then((res) => {
						if (res.data.code === 200) {
							Toast.success('设置成功');
							this.params.page = 1;
							this.queryOrderList();
						} else {
							Toast.fail(res.data.message);
						}
					});
				});
			},
			notInstall(index, item) {
				Dialog.confirm({
					title: '暂不安装',
					message: '请确认该笔订单是否暂不安装',
				}).then(() => {
					this.axios({
						method: 'put',
						url: '/v1/h5/order-info/not-install/' + item.id,
						params: this.addRemarkParams,
					}).then((res) => {
						if (res.data.code === 200) {
							Toast.success('设置成功');
							if (this.params.status === 0) {
								this.orderList[index].status = 5;
							} else {
								this.params.page = 1;
								this.queryOrderList();
							}
							this.showRemarks = false;
						} else {
							Toast.fail(res.data.message);
						}
					});
				});
			},
		},
	};
</script>

<style>
	.van-field__label {
		color: rgb(120, 120, 120) !important;
	}

	.van-field__control {
		color: rgb(180, 180, 180) !important;
	}
</style>
